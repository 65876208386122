.containerHeader {
    width: 100%;
    height: 55px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E2E7E9;
    position: fixed;
    top: 0;
    z-index: 1000!important;
    background-color: #ffffff;
    min-width: 320px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnMenu {
    border: none;
    padding: 0px;
    margin-right: 10px;
    display: none;
}

.iconMenu {
    font-size: 30px;
    color: #000000;
}

.titleLogo {
    font-family: 'Times New Roman';
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #000;
}

.btnYoutube {
    background-color: #FF4858;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    border: none;
}

.btnYoutube:hover {
    background-color: #FF4858!important;
    border: none!important;
    color: #ffffff!important;
}

.iconYoutube {
    font-size: 18px;
}

.divMenuLateralIzquierdo {
    width: 256px;
    height: calc( 100vh - 55px );
    padding-left: 2%;
    position: fixed;
    background-color: #ffffff;
}

.menuLateralDerecho {
    width: 100%;
    height: calc( 100% - 40px);
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    border-right: 1px solid #E2E7E9!important;
}

.divFooterMenu {
    width: 100%;
    height: 40px;
    border-right: 1px solid #E2E7E9!important;
}

.titleFooterMenu {
    color: #767777;
    font-size: 10px;
    font-weight: 600;
}

.divMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 55px;
}

.divMainChildren {
    width: calc( 100% - 556px);
    min-width: 320px;
    margin-left: 256px;
    padding: 20px;
}

.divMenuLateralDerecho {
    width: 300px;
    padding: 10px;
}

.divContainerRedes {
    display: flex;
    flex-direction: column;
    background-color: #F9FAFA;
    padding: 15px;
    border-radius: 10px;
}

.divContainerRedesSobreMi {
    display: flex;
    flex-direction: column;
    background-color: #F9FAFA;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.h2TitleRedes {
    font-size: 12px;
    font-weight: 600;
    color: #576F76;
    margin-bottom: 12px;
}

.divContainerBtnRedes {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.divContainerBtnSobreMi {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.btnRedes {
    width: 100%;
    height: 45px;
    margin-bottom: 10px;
    background-color: #F9FAFA;
    color: #000000;
    display: flex;
    align-items: center;
    border: none!important;
    box-shadow: none;
}

.btnRedesSobreMi {
    width: 100%;
    height: 45px;
    background-color: #F9FAFA;
    color: #000000;
    display: flex;
    align-items: center;
    border: none!important;
    box-shadow: none;
}

.btnVolverContenido {
    width: 45px!important;
    height: 45px!important;
    color: #000000;
    background-color: #F9FAFA;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    box-shadow: none;
}

.btnRedes:hover {
    background-color: #EAEDEF!important;
    color: #000000!important;
}

.btnRedesSobreMi:hover {
    background-color: #EAEDEF!important;
    color: #000000!important;
}

.iconRedesYoutube {
    color: #FF4858;
}

.divMenuLateralIzquierdo {
    display: flex;
    flex-direction: column;
}

.drawerMenu {
    padding: 0px!important;
}

.menuLateralDerechoDrawer {
    width: 100%;
    height: calc( 100% - 40px);
    font-size: 13px;
    font-weight: 500;
    border: none!important;
}

.contDrawer {
    height: calc( 100vh - 40px );
}

@media (max-width: 450px) {
    .btnYoutube {
        background-color: #FF4858;
        color: #ffffff;
        font-weight: 600;
        display: flex;
        align-items: center;
        border: none;
    }

    .iconYoutube {
        font-size: 14px;
    }

    .logo {
        padding-left: 80px;
        width: 20px;
    }

    .divFooterMenu {
        border-right: none!important;
    }

    .titleLogo {
        font-size: 16px!important;
    }
}

@media (max-width: 800px) {
    .titleVisible {
        visibility: hidden;
    }
    .titleVisible2 {
        visibility: visible;
    }

    .divMenuLateralIzquierdo {
        display: none;
    }

    .divMainChildren {
        width: calc( 100% + 300px);
        margin-left: 0px;
    }

    .btnMenu {
        display: flex;
    }

    .divMenuLateralDerecho {
        display: none;
    }

    .divFooterMenu {
        border-right: none!important;
    }

    .titleLogo {
        font-size: 18px;
    }
}

@media (min-width: 801px) and (max-width: 1200px) {
    .titleVisible {
        visibility: visible;
    }
    .titleVisible2 {
        visibility: hidden;
    }

    .divMenuLateralIzquierdo {
        display: none;
    }

    .divMainChildren {
        width: calc( 100% - 300px);
        margin-left: 0px;
    }

    .btnMenu {
        display: flex;
    }
}

@media (min-width: 1201px) {
    .titleVisible2 {
        visibility: hidden;
    }
}
  