@media (max-width: 450px) {
    
    .h1TitlePageContenido {
        font-size: 23px!important;
    }

}

@media (max-width: 800px) {
    
    .h1TitlePageContenido {
        font-size: 28px!important;
    }
}