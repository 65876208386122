.divPadreCardElectronica {
    width: '100%';
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E2E7E9;
}

.divContainerContElectronica {
    width: 100%;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #FFFFFF;
}

.divContainerContElectronica:hover {
    background-color: #F2F4F5;
}

.divHeaderCardElectronica {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.divContentPopover {
    max-width: 700px;
}

.popoverCardElectronica {
    background-color: #EAEDEF;
    border-radius: 50%;
    padding: 4px;
}

.divHeaderCardElectronica {
    font-size: 12px;
    color: #576F76;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.divTitleCardElectronica {
    width: 100%;
}

.h1TitleElectronica {
    font-size: 16px;
    font-weight: 700;
}

.h1TextBody {
    color: #697074;
    font-size: 14px;
}

.divContainerImagenElectronica {
    width: 100%;
    margin-top: 10px;
}

.imgCardElectronica {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

.divContainerCardBotonesElectronica {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.divContainerBtnYutuCardElectronica {
    width: 140px;
    background-color: #EAEDEF!important;
    padding: 3px 8px;
    border-radius: 8px;
}

.divContainerBtnYutuCardElectronica:hover {
    background-color: #F9FAFA!important;
}

.divBtnYoutuCardElectronica {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.h1TitelYoutuElectronica {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

@media (max-width: 450px) {
    .popover1 {
        visibility: hidden;
    }

    .popover2 {
        visibility: visible;
    }
}

@media (max-width: 800px) {
    .popover1 {
        visibility: hidden;
    }

    .popover2 {
        visibility: visible;
    }
}

@media (min-width: 801px) and (max-width: 1200px) { 
    .popover1 {
        visibility: visible;
    }

    .popover2 {
        visibility: hidden;
    }
}

@media (min-width: 1201px) {
    .popover1 {
        visibility: visible;
    }
    
    .popover2 {
        visibility: hidden;
    }
}