.divContainerImagenElectronicaContenido {
    border-radius: 8px!important;
}

.imgCardElectronicaContenido {
    border-radius: 8px!important;
}

.subTitleContenido {
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 20px;
}

.divTextoContenido {
    margin-top: 35px;
}

.pTextoContenido {
    margin-top: 30px;
    font-size: 16px;
    color: #697074;
}

.pTextoDescripcionContenido {
    font-size: 16px;
    color: #697074;
    margin-top: 30px;
}